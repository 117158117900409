<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <form action="" class="row shadow-sm p-3 bg-white" v-on:submit.prevent="sendPqr">
                    <div class="col-12 mb-2">
                        <label for="nombre" class="form-label">Nombres</label>
                        <input type="text" class="form-control" id="nombre" placeholder="Nombre y apellido" required v-model="domfData.name">
                    </div>
                    <div class="col-5 mb-2">
                        <label for="nombre" class="form-label">Numero de Documento</label>
                        <input type="number" class="form-control" id="nombre" placeholder="Numero de documento" required  v-model="domfData.document">
                    </div>
                    <div class="col-7 mb-2">
                        <label for="nombre" class="form-label">Correo</label>
                        <input type="email" class="form-control" id="nombre" placeholder="correo@mail.com" required  v-model="domfData.email">
                    </div>
                    <div class="col-6 mb-2">
                        <label for="nombre" class="form-label">Ciudad</label>
                        <input type="text" class="form-control" id="nombre" placeholder="Ciudad" required  v-model="domfData.city">
                    </div>
                    <div class="col-6 mb-2">
                        <label for="nombre" class="form-label">Dirección</label>
                        <input type="text" class="form-control" id="nombre" placeholder="Dirección" required  v-model="domfData.neighborhood">
                    </div>
                    <div class="col-12 mb-2">
                        <label for="nombre" class="form-label">Telefono</label>
                        <input type="tel" class="form-control" id="nombre" placeholder="Telefono" required  v-model="domfData.phone">
                    </div>
                    <div class="col-12 mb-2">
                        <label for="nombre" class="form-label">Título</label>
                        <input type="text" class="form-control" id="nombre" placeholder="Título" required  v-model="domfData.title">
                    </div>
                    <div class="col-12 mb-2">
                        <label for="nombre" class="form-label">Redacte su petición</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" required  v-model="domfData.body"></textarea>
                    </div>

                    <div class="col-12 mt-3 text-right">
                        <button type="submit" class="btn tema-principal">
                            Enviar
                        </button>
                    </div>
                </form>
            </div>
        </div>
        {{ addEtiqueta }}
    </div>
</template>
