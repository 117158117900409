<template>
    <section class="d-block">
        <h1 class="font-weight-bold color-principal mb-5">Preguntas Frecuentes</h1>
        <article class="d-block mb-4" v-for="item in preguntas" :key="item">
            <h5 class="color-principal mb-4">{{ item.title }}</h5>
            <p>{{ item.respuesta }}</p>
        </article>
        <div class="accordion mt-5" id="accordionExample">
            <div class="card">
                <div class="card-header" id="uno">
                    <h2 class="mb-0">
                        <button  class="btn btn-link tema-principal btn-block text-left" type="button" data-toggle="collapse" data-target="#colluno" aria-expanded="true" aria-controls="colluno">
                            Horarios de viajes
                        </button>
                    </h2>
                </div>
                <div id="colluno" class="collapse" aria-labelledby="uno" data-parent="#accordionExample">
                    <div class="card-body">
                        Si desea reservar un tiquete de viaje puede reservarlos sin hacer largas filas, fácil y rápido, haciendo click en el siguiente enlace <a href="https://terminaldesantamarta.com/">Buscar y comprar tiquete de viaje</a>. Siga los tres pasos y tendrá su tiquete por correo electrónico
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="dos">
                    <h2 class="mb-0">
                        <button class="btn btn-link tema-principal btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#colldos" aria-expanded="false" aria-controls="colldos">
                            Destinos
                        </button>
                    </h2>
                </div>
                <div id="colldos" class="collapse" aria-labelledby="dos" data-parent="#accordionExample">
                    <div class="card-body">
                        Aca puedes encontart todos nuestros destinos<br>
                        <router-link to="/destinos">Ir a destino</router-link>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="tres">
                    <h2 class="mb-0">
                        <button class="btn btn-link tema-principal btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#colltres" aria-expanded="false" aria-controls="colltres">
                            Horarios de atención
                        </button>
                    </h2>
                </div>
                <div id="colltres" class="collapse" aria-labelledby="tres" data-parent="#accordionExample">
                    <div class="card-body">Atención Administrativa (de oficina)<br>De lunes a viernes: 8 am - 12m | 2pm -6pm</div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="cuatro">
                    <h2 class="mb-0">
                        <button class="btn btn-link tema-principal btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collcuatro" aria-expanded="false" aria-controls="collcuatro">
                            Transporte de mascotas
                        </button>
                    </h2>
                </div>
                <div id="collcuatro" class="collapse" aria-labelledby="cuatro" data-parent="#accordionExample">
                    <div class="card-body">
                        And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="cuatro">
                    <h2 class="mb-0">
                        <button class="btn btn-link tema-principal btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collcinco" aria-expanded="false" aria-controls="collcinco">
                            Servicios disponibles en la terminal
                        </button>
                    </h2>
                </div>
                <div id="collcinco" class="collapse" aria-labelledby="cinco" data-parent="#accordionExample">
                    <div class="card-body">
                        And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { preguntas } from '@/assets/data/preguntas-frecuentes.js'

export default {
    name: 'PreguntasFrecuentes',

    data() {
        return {
            preguntas: preguntas
        }
    }
}
</script>
<style lang="css" scoped>
.accordion button {
    text-decoration: none;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 0;
    font-weight: bold;
    text-transform: uppercase;
}
.accordion .card-header {
    text-decoration: none;
    padding: 0;
}
.accordion .card {
    border-color: #ff6d00;
}
.accordion .card + .card {
    border-top: 2px solid #fff;
}
</style>
