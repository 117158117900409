<template>
    <div class="container-fluid">
        <PortadaHeader :msj="msj"></PortadaHeader>
        <div class="container">
            <div :class="['row seccion-entidad align-items-start', { 'enpujar' : activo } ]">
                <div class="col-lg-4 col-6">
                    <div class="list-group position-sticky" id="list-tab" role="tablist" style="top: 5rem;">
                        <a @click="verSeleccionado" class="list-group-item list-group-item-action active" id="list-pqrs-list" data-toggle="list" href="#list-pqrs" role="tab" aria-controls="pqrs">Peticion queja y reclamo</a>
                    </div>
                </div>
                <div class="col-lg-8 col-6 lista-texto">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="d-block mb-3 boton-volver">
                            <button class="btn tema-principal" @click="verSeleccionado">
                                Volver
                            </button>
                        </div>
                        <div class="tab-pane fade show active" id="list-pqrs" role="tabpanel" aria-labelledby="list-pqrs-list">
                            <div class="seccion-user-title shadow-sm">
                                <div class="bloq-auto tema-principal">
                                    <i class="icon-user"></i>
                                </div>
                                <div class="bloq">
                                    <h4 class="m-0">Redactar PQR</h4>
                                    <p class="m-0">datos del solicitante</p>
                                </div>
                            </div>
                            <pqr></pqr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import pqr from '@/components/entidad/peticionesQuejas/pqr/pqr.vue';
import PortadaHeader from '@/components/global/PortadaHeader.vue';
export default {
    name: 'PeticioneQuejas',
    data() {
        return {
            activo: false,
            msj: {
                titulo: 'PQRSD',
                desc: 'Peticiones, Quejas, Reclamos, Sugerencias y Denuncias.',
                img: 'foto-1.jpg'
            }
        }
    },
    methods: {
        verSeleccionado(){
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        }
    },
    components: {
        PortadaHeader,
        pqr
    }
}
</script>
<style lang="css" scoped>
</style>
