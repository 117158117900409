<template>
    <div>
        <section>
            <strong class="color-principal">RESOLUCION NO 047 - 15 de Junio de 2012 </strong>
            <h2 class="h4 mb-3 mt-2">POR LA CUAL SE ADOPTA EL MANUAL OPERATIVO PARA LA CENTRAL
                DE TRANSPORTE DE SANTA MARTA Y SE DICTAN OTRAS
                DISPOSICIONES.</h2>
            <p>El Gerente de la Sociedad Central de <strong>Transporte de Santa Marta, L.T.D.A</strong> en
                uso de sus facultades constitucionales, legales y estatutarias, y en especial las
                contenidas en la ley 105 de 1993, ley 336 de 1996, ley 769 de 2002, el Decreto
                2762 del 20 de Diciembre de 2001, Decreto 171 del 5 de febrero de 2001, y </p>
            <ul>
                <li class="mb-3">Que corresponde al Gerente de la <strong>CENTRAL DE TRANSPORTE DE
                        SANTA MARTA L.T.D.A.</strong> dirigir la acción administrativa y Operativa de
                    la Terminal de Transporte en su jurisdicción, con sujeción a la Ley y
                    estatutos.</li>
                <li class="mb-3">
                    Que el numeral 3 del artículo 13 del Decreto # 2762, establece que es
                    obligatorio para las empresas Terminales de Transportes Terrestres
                    Automotor por carretera, elaborar y aplicar su propio Manual Operativo
                    de conformidad con las normas vigentes o las que se expidan para su
                    propio fin
                </li>
                <li class="mb-3">
                    Que se hace necesario reformar el Manual Operativo de la Central de
                    Transporte de Santa Marta, a efectos de establecer una estructura
                    mínima que delimite y codifique cada una de sus áreas, estableciendo
                    los procedimientos y protocolos que deben cumplir todas las Empresas
                    de Transportes, trabajadores y usuarios en general, en desarrollo de su
                    objeto social.
                </li>
                <li class="mb-3">
                    Que igualmente se hace necesario fijar en el Manual Operativo las
                    nuevas políticas estatales, que en materia de seguridad, están obligadas
                    a cumplir las Terminales de Transportes, a efectos de ofrecer mejores
                    condiciones y garantías para la prestación de un optimo servicio público
                    esencial a los usuarios del servicio
                </li>
            </ul>
        </section>
        <Accordion :items="accordion"></Accordion>
    </div>
</template>
<script>
import Accordion from '@/components/global/Accordion.vue';
export default {
    name: 'ManualOperativo',
    data: function() {
        return {
            accordion: [
                {
                    id_tab: 'uni',
                    title: 'ARTÍCULO PRIMERO',
                    contexto: 'Refórmese y adiciónese el manual operativo de la Central de Transporte de Santa Marta, contenido en la Resolución No 084 de 2001, a efectos de garantizar una mejor prestación del servicio de transporte público de pasajeros por carretera, conforme a las disposiciones legales, regulaciones, prohibiciones , sanciones y procedimientos que se incorporan en la presente resolución.'
                },
                {
                    id_tab: 'dos',
                    title: 'ARTÍCULO SEGUNDO - REGIMEN JURIDICO',
                    contexto: 'Hacen parte integrante del presente Manual Operativo de la Central de Transportes de Santa Marta, las normas, procedimientos, derechos, obligaciones, prohibiciones, sanciones y procedimientos, contenidos en la ley 105 de 1993, ley 336 de 1996, ley 769 de 2002, Decreto reglamentario 2762 de 2001, Decreto 171 de 2003; Decreto 3336 de 2003 y demás normas que los adicionen, modifiquen o complementen, subroguen o revoquen.'
                },
                {
                    id_tab: 'tre',
                    title: 'ARTÍCULO TERCERO - PRINCIPIOS',
                    contexto: 'Para la aplicación del presente Manual Operativo, se deberá interpretar y aplicar las disposiciones que regulan las actuaciones y procedimientos administrativos a la luz de los principios consagrados en la Constitución Política y en las leyes especiales que regulan la materia. '
                },
            ]
        }
    },
    components: {
        Accordion
    }
}
</script>
<style lang="css" scoped>
</style>