<template src="@/views/entidad/entidad-template.vue"></template>
<style src="@/views/entidad/entidad.css"></style>

<script>
import AtencionCiudadano from '@/components/entidad/AtencionCiudadano.vue';
import PlaneacionGestion from '@/components/entidad/PlaneacionGestion.vue';
import PeticioneQuejas from '@/components/entidad/PeticioneQuejas.vue';
import InformacionCorporativa from '@/components/entidad/InformacionCorporativa.vue';
import tranparenciaAcceso from '@/components/entidad/tranparenciaAcceso2.vue';
import Contrataciones from '@/components/entidad/Contrataciones.vue';
export default {
    name: 'Entidad',
    components: {
        AtencionCiudadano,
        PeticioneQuejas,
        InformacionCorporativa,
        PlaneacionGestion,
        tranparenciaAcceso,
        Contrataciones,
    }
}
</script>