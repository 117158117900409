<template>
    <div class="container-fluid">
        <PortadaHeader :msj="msj"></PortadaHeader>
        <div class="container mt-5">
            <div class="row tab-section">
                <!-- Nav tabs -->
                <div class="col-12 header">
                    <ul class="nav nav-tabs borde-color-principal">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#home" @click="nfondo('info-mision.jpg')">
                                <div class="d-block">
                                    Mision
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu1" @click="nfondo('info-vision.png')">
                                <div class="d-block">
                                    Vision
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu2" @click="nfondo('info-estrategia.png')">
                                <div class="d-block">
                                    Objetivos Estrategicos
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#menu3" @click="nfondo('info-calidad.png')">
                                <div class="d-block">
                                    Politicas de calidad
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- Tab panes -->
                <div class="col-12">
                    <div class="tab-content">
                        <div class="tab-pane active container py-4" id="home">
                            <p>
                                Ofrecer una infraestructura adecuada que posibilite la operación del transporte público terrestre de pasajeros y servicios conexos de una manera segura y cómoda que satisfaga las necesidades y expectativas de nuestros clientes, con el apoyo de un talento humano competente y contribuyendo a la mejora continua de la organización y del servicio.
                            </p>
                        </div>
                        <div class="tab-pane container py-4" id="menu1">
                            <p>Para el 2024, consolidarnos en la región Caribe como una empresa líder en la prestación de servicios de terminal de transporte terrestre de pasajeros, brindando condiciones de seguridad y comodidad a nuestros usuarios.</p>
                        </div>
                        <div class="tab-pane container py-4" id="menu2">
                            <ol>
                                <li>Aumentar la Satisfacción de los usuarios.</li>
                                <li>Mejorar continuamente el desempeño de los procesos del sistema de Gestión de Calidad.</li>
                                <li>Infraestructura adecuada a nuestros propósitos.</li>
                                <li>Aumentar la rentabilidad y sostenibilidad financiera de la terminal</li>
                            </ol>
                        </div>
                        <div class="tab-pane container py-4" id="menu3">
                            <p>La Central de Transportes de Santa Marta Ltda está comprometida con la operación eficiente del servicio de terminal de transportes, propiciando dentro de nuestras instalaciones comodidad, seguridad e información; que satisfaga las necesidades y expectativas de nuestros clientes, contando con el apoyo de talento humano competente, una infraestructura adecuada a nuestro propósito y el cumplimiento de los requisitos legales, que contribuya a mejorar continuamente la eficacia, eficiencia y efectividad del sistema de Gestión de la Calidad de la organización y del servicio.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PortadaHeader from '@/components/global/PortadaHeader.vue'
export default {
    name: 'InformacionCorporativa',
    data() {
        return {
            msj: {
                titulo: 'Mi terminal',
                desc: 'Información Corporativa',
                img: 'info-mision.jpg'
            }
        }
    },
    methods: {
        nfondo(i){
            this.msj.img = i;
        }
    },
    components: {
        PortadaHeader
    }
}
</script>
<style>
.tab-section .header .nav-item .nav-link {
    display: block;
    text-align: center;
    padding: 20px 35px;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
}

.tab-section .header .nav-item i {
    font-size: 2.5rem;
}

@media (max-width: 768px) {
    .tab-section .nav-tabs {
        display: flex;
    }
    .tab-section .nav-tabs {
        display: flex;
        align-items: flex-end;
    }
    .tab-section .nav-tabs .nav-item {
        width: 25%;
    }
    .tab-section .nav-tabs .d-block {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }
}
</style>