<template>
    <div>
        <AtencionCiudadano v-if="$route.params.id === 'AtencionCiudadano'"></AtencionCiudadano>
        <PeticioneQuejas v-else-if="$route.params.id === 'peticiones-quejas'"></PeticioneQuejas>
        <InformacionCorporativa v-else-if="$route.params.id === 'informacion-corporativa'"></InformacionCorporativa>
        <PlaneacionGestion v-else-if="$route.params.id === 'planeacion-y-gestion'"></PlaneacionGestion>
        <tranparenciaAcceso v-else-if="$route.params.id === 'tranparenciaAcceso'"></tranparenciaAcceso>
        <FuncionesDeberesAnexos v-else-if="$route.params.id === 'funciones-deberes-anexos'"></FuncionesDeberesAnexos>
        <Contrataciones v-else-if="$route.params.id === 'Contrataciones'"></Contrataciones>
        <Contrataciones v-else-if="$route.params.id === 'FuncionesDeberesAnexos'"></Contrataciones>
    </div>
</template>