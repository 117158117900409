<template>
    <TabsSideContainer :tabs="tabs">
        <template v-slot:body>
            <div class="tab-pane fade show active" id="tab-uno" role="tabpanel">
                <PreguntasFrecuentes></PreguntasFrecuentes>
            </div>
            <div class="tab-pane fade" id="tab-dos" role="tabpanel">
                <Pqrs></Pqrs>
            </div>
            <div class="tab-pane fade" id="tab-tres" role="tabpanel">
                <DatosPersonales></DatosPersonales>
            </div>
            <div class="tab-pane fade" id="tab-cuatro" role="tabpanel">
                <LineaAtencion></LineaAtencion>
            </div>
            <div class="tab-pane fade" id="tab-cinco" role="tabpanel">
                <InformeGestion></InformeGestion>
            </div>
            <div class="tab-pane fade" id="tab-seis" role="tabpanel">
                <Contratos></Contratos>
            </div>
        </template>
    </TabsSideContainer>
</template>

<script>
import ManualOperativo from '@/components/ver-transparencia-acceso/funciones-deberes-anexos/ManualOperativo.vue';
import PortadaHeader from '@/components/global/PortadaHeader.vue'
import PreguntasFrecuentes from '@/components/entidad/transparencia/PreguntasFrecuentes.vue'
import DatosPersonales from '@/components/entidad/transparencia/DatosPersonales.vue'
import LineaAtencion from '@/components/entidad/transparencia/LineaAtencion.vue'
import InformeGestion from '@/components/entidad/transparencia/InformeGestion.vue'
import Contratos from '@/components/entidad/transparencia/Contratos.vue'
import Pqrs from '@/components/entidad/transparencia/Pqrs.vue'
import TabsSideContainer from '@/components/templates/tabs-side-container.vue'
export default {
    name: 'AtencionCiudadano',
    data() {
        return {
            activo: false,
            tabs: [
                {
                    id: 'uno',
                    name: 'Preguntas Frecuentes'
                },
                {
                    id: 'dos',
                    name: 'PQRS'
                },
                {
                    id: 'tres',
                    name: 'Politicas de tratamiento de Datos Personales'
                },
                {
                    id: 'cuatro',
                    name: 'Linea de atencion'
                },
                {
                    id: 'cinco',
                    name: 'Informe de Gestion'
                },
                {
                    id: 'seis',
                    name: 'Contratos'
                },
            ],
            msj: {
                titulo: 'Contactenos',
                desc: 'Atencion al ciudadano',
                img: 'foto-2.jpg'
            }
        }
    },
    components: {
        PreguntasFrecuentes,
        DatosPersonales,
        LineaAtencion,
        InformeGestion,
        ManualOperativo,
        Contratos,
        PortadaHeader,
        TabsSideContainer,
        Pqrs
    },
    methods: {
        verSeleccionado(){
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        }
    }
}
</script>