<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12 mb-4">
                <h1 class="color-principal mb-4">Transparencia y Acceso a la Información Pública.</h1>
                <div class="d-block ml-5 pb-2">
                    <h2 class="h5 color-principal">De acuerdo a la Ley 1712 de 2014 y a la Resolución 1519 de 2020</h2>
                    <p>MinTIC pone a disposición de los ciudadanos, sector TIC e interesados, la nueva sección de Transparencia y Acceso a la Información Pública Nacional, donde podrán conocer de primera mano la información del Ministerio de Tecnologías de la Información y las Comunicaciones.</p>
                    <p>Según lo dicta la Ley, la información generada por las entidades del Estado no podrá ser reservada o limitada, por el contrario es de carácter público.. En este sitio se proporciona y facilita el acceso a la misma en los términos más amplios posibles en el momentos</p>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-12" v-for="(item, index) of documentos" v-bind:key="index">
                        <div class="d-block">
                            <h3 class="mb-4 h4 title-section-list">{{ item.index }}. {{ item.title }}</h3>
                            <ul class="list-group tt-list">
                                <li class="list-group-item" v-for="(item2, index2) of item.group" v-bind:key="index2">
                                    <template v-if="item2.url.externa">
                                        <a :href="item2.url.url" target="_blank">
                                            <span class="indice">{{ item.index }}.{{ item2.index }}</span>
                                            <span class="title">{{ item2.title }}</span>
                                            <i class="icon-new-tab ml-3"></i>
                                        </a>
                                    </template>
                                    <template v-else>
                                        <router-link :to="item2.url.url" @click="gotop()">
                                            <span class="indice">{{ item.index }}.{{ item2.index }}</span>
                                            <span class="title">{{ item2.title }}</span>
                                        </router-link>
                                    </template>
                                    <div v-if="item2.description">
                                        <small class="text-muted">{{ item2.description }}</small>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import axios from 'axios';
import PortadaHeader from '@/components/global/PortadaHeader.vue';
import InformeGestion from '@/components/entidad/transparenciaAcceso/InformeGestion.vue';
import ControlInterno from '@/components/entidad/transparenciaAcceso/ControlInterno.vue';
export default {
    name: 'tranparenciaAcceso',
    created() {
        this.queryIndice();
    },
    data() {
        return {
            activo: false,
            msj: {
                titulo: 'Entidad',
                desc: 'Transparencia y acceso',
                img: 'foto-2.jpg'
            },
            url: 'https://terminaldesantamarta.com/documentos/',
            documentos: [],
        }
    },
    components: {
        PortadaHeader,
        ControlInterno,
        InformeGestion
    },
    methods: {
        verSeleccionado() {
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        },
        tranformMayus(texto){
            return texto.toUpperCase();
        },
        gotop(){
            window.scrollTo(0, 0);
        },
        async queryIndice(){
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/items-transparencias`,
                    responseType: 'json',
                })
                .then(res => {
                    const grupo = res.data.map(item => ({
                        id: item.id,
                        index: item.Indice,
                        title: item.Titulo,
                        group: []
                    }))
                    this.querySubitem(grupo);
                }).catch(err => console.err(err));
        },
        isUrlExterna(url, file){
            if(file !== null){
                return {
                    externa: true,
                    url: 'https://terminaldesantamarta.com/documentos/' + file.name
                }
            }
            else if(url != null) {
                const isExterna = url.search(/(http:|https:\/\/)/) === -1;
                if(!isExterna) {
                    return {
                        externa: true,
                        url: url
                    };
                } else {
                    return {
                        externa: false,
                        url: url
                    };
                }
            }
            else {
                return {
                    externa: false,
                    url: '/entidad/tranparenciaAcceso'
                }
            }
        },
        async querySubitem(group){
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/subitem-transparencias`,
                    responseType: 'json',
                })
                .then(res => {
                    group.forEach(item => {
                        res.data.forEach(subitem => {
                            if(subitem.items_transparencia.id){
                                if(item.id === subitem.items_transparencia.id){
                                    item.group.push({
                                        title: subitem.Titulo.toUpperCase(),
                                        index: subitem.Indice,
                                        url: this.isUrlExterna(subitem.Url, subitem.Archivo),
                                        description: subitem.descripcion
                                    })
                                    // item.group.push(this.isUrlExterna(subitem.Url, subitem.Archivo))
                                }
                            } else {
                                console.warn('item sin indice padre', subitem)
                            }
                        })
                    })
                        // console.log("group", group);
                    this.documentos = group;
                }).catch(err => console.err(err));
        }
    },
    computed: {
        ...mapState(['urlServer'])
    }
}
</script>
<style lang="css">
.title-section-list {
    font-weight: bold;
    font-size: 1.25rem;
    display: block;
    padding: 0 1.5rem;
    margin-bottom: 0;
    color: #f29135;
}

.tt-list {
    padding: 1.5rem 2rem;
    background-color: #fff;
    border-radius: 0 0 1rem 1rem;
    border-width: 4px 1px 1px 1px;
    border-style: solid;
    box-shadow: -5px 5px 16px -3px rgba(0, 0, 0, 0.15);
    border-color: #F2BA35 rgba(0, 0, 0, 0.075) rgba(0, 0, 0, 0.075) rgba(0, 0, 0, 0.075);
}

.tt-list li {
    background-color: transparent;
    border: 0;
    padding: .75rem 0;
}

.tt-list li+li {
    border-top: 1px solid rgba(139, 139, 139, .25) !important;
}

.tt-list {
    margin-bottom: 3rem;
}

.tt-list i.icon-file-pdf {
    color: #FF3434;
}

.tt-list i.icon-file-excel {
    color: #57D834;
}

.tt-list i.icon-file-word {
    color: #5474FF;
}

.tt-list a {
    font-weight: 500;
    text-decoration: none;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}
.tt-list a .indice {
    font-weight: bold;
    display: inline-block;
    margin-right: .75rem;
}
</style>