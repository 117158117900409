<template>
    <section class="d-block">
        <h1 class="font-weight-bold color-principal mb-5">Política de Tratamiento de Datos Personales</h1>
        <article class="mb-5">
            <p>La Terminal publica la Política de Tratamiento de Datos Personales.</p>
            <p>Para efecto de atender las peticiones, consultas y reclamos que el titular pueda tener sobre el tratamiento de sus datos personales y en garantía de sus derechos a conocer, actualizar, rectificar y suprimir sus datos personales contacte al área encargada en el buzón de correo electrónico tratamientodedatos@terminaldetransporte.gov.co y/o la dirección física diagonal 23 No. 69 - 60 oficina 502 de Bogotá D.C., Colombia.</p>
            <p>Igualmente puede consultar el procedimiento completo en el documento de "Política de Tratamiento de Datos Personales"</p>
        </article>
        <article>
            <div class="d-grid-pdf">
                <router-link class="columna" to="/ver-transparencia-acceso/avisoPrivacidad">
                    <div class="d-flex align-items-center mb-1">
                        <i class="icon-file-text h3 mr-2"></i>   INFORMACIÓN
                    </div>
                    <h6 class="m-0 text-uppercase">Aviso y Privacidad</h6>
                </router-link>
            </div>
        </article>
    </section>
</template>
<script>
export default {
    name: 'DatosPersonales',
    data() {
        return {

        }
    }
}
</script>
<style lang="css" scoped>
</style>