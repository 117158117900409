<template>
    <div>
        <h1 class="font-weight-bold color-principal mb-5">Informe de Gestión</h1>
        <p>La Gerencia de la Central de Transporte de Santa Marta, sigue trabajando para cumplir con las
            leyes, decretos, normas, resoluciones, y planes de acción, que le imponen entidades como la
            Superintendencia de Puertos y Transportes, Ministerio de Transporte, Contaduría General de la
            Nación, DIAN, DADSA, Secretaria de Salud entre otros, en razón a su actividad económica y
            naturaleza jurídica; siendo que está clasificada como una Empresa Industrial y Comercial del
            Estado. Y con esto, tratamos de suplir las deficiencias, corregir errores, y adaptar la empresa a
            los nuevos retos que establece su actividad en el ámbito local, nacional e internacional</p>
        <div class="container-fluid mb-4">
            <div class="row">
                <div class="col-11 offset-1">
                    <h3>Situación Juridica</h3>
                    <p>Se incrementó una política de cobro en lo que respecta al pago de canon de
                        arrendamiento de los diferentes inmuebles que posee la empresa dado que se había
                        vuelto costumbre realizar dichos pagos fuera de los términos establecidos en los
                        contratos con resultado positivo hasta la fecha</p>
                </div>
            </div>
        </div>
        <LoopPdf :loop="pdf"></LoopPdf>
    </div>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue'
export default {
    name: 'InformeGestion',
    data: function() {
        return {
            pdf: [{
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/03/InformeGesti%C3%B3n.pdf',
                    titulo: 'Informe de Gestión 2018'
                },{
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2020/01/INFORME-DE-GESTION-A%C3%91O-2019.pdf',
                    titulo: 'Informe de Gestión 2019'
                },
            ]
        }
    },
    components: {
        LoopPdf
    }
}
</script>
<style lang="css" scoped>
</style>