<template>
    <div class="container-fluid">
        <div class="container container-seccion-entidad pt-4">
            <div :class="['row seccion-entidad align-items-start', { 'enpujar' : activo } ]">
                <div class="col-lg-4 col-6">
                    <div class="list-group position-sticky" id="list-tab" role="tablist" style="top: 5rem;">
                        <a
                            v-for="(tab, index) in tabs"
                            :key="index"
                            @click="verSeleccionado"
                            :class="['list-group-item list-group-item-action', {'active' : index === 0}]"
                            data-toggle="list"
                            :href="'#tab-' + tab.id"
                            role="tab"
                            :aria-controls="tab.id">{{ tab.name }}
                        </a>
                    </div>
                </div>
                <div class="col-lg-8 col-6 lista-texto">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="d-block mb-3 boton-volver">
                            <button class="btn tema-principal" @click="verSeleccionado">
                                Volver
                            </button>
                        </div>
                        <slot name="body"></slot>
                        <!-- <div class="tab-pane fade show active" id="tab-uno" role="tabpanel">
                            queso 1
                        </div>
                        <div class="tab-pane fade" id="tab-dos" role="tabpanel">
                            queso 2
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- script -->
<script>
export default {
    name: 'TabsSideContainer',
    props: {
        tabs: {
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            activo: false
        }
    },
    methods: {
        verSeleccionado(){
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        }
    }
}
</script>
<!-- style -->
<style scoped lang="scss">

</style>
