<template>
    <section class="d-block">
        <h1 class="font-weight-bold color-principal mb-5">PQRS</h1>
        <article class="d-block mb-5">
            <p>Para radicar solicitudes ante La Terminal por favor haga consulte el Sistema Distrital de Quejas y Soluciones en la página web del Gobierno de Bogotá. Para consultar el estado de su Petición, Queja, Reclamo o Solicitud de clic en el siguiente enlace.</p>
            <router-link :to="'/entidad/peticiones-quejas'"  class="btn tema-principal mr-2">
                Radicar PQRS
            </router-link>
            <button class="btn tema-principal">
                Consultar PQRS
            </button>
            <p class="mt-3">Para consultar la información detallada diríjase a Datos Abiertos</p>
        </article>
        <LoopPdf :loop="pdf"></LoopPdf>
    </section>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue'
export default {
    name: 'Pqrs',
    data() {
        return {
            pdf: [
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/03/Informe-de-Seguimiento-PQRS-Primer-Semestre-2018.pdf',
                    titulo: 'PQRS Primer Semestre 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/03/Informe-de-Seguimiento-PQRS-Segundo-Semestre-2018.pdf',
                    titulo: 'PQRS segundo Semestre 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/08/SEGUIMIENTO-A-LOS-DERECHOS-DE-PETICI%C3%93N.pdf',
                    titulo: 'PQRS Primer Semestre 2019'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2020/01/INFORME-DE-SEGUIMIENTO-A-LAS-PQRS-DE-JULIO-A-DICIEMBRE-31-DE-2019.pdf',
                    titulo: 'PQRS segundo Semestre 2019'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2020/12/SEGUIMIENTO-A-LOS-DERECHOS-DE-PETICI%C3%93N-A-junio-30-DE-2020.pdf',
                    titulo: 'PQRS Primer Semestre 2020'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2021/01/INFORME-DE-SEGUIMIENTO-A-LAS-PQRS-DICIEMBRE-31-2020.pdf',
                    titulo: 'PQRS segundo Semestre 2020'
                }
            ]
        }
    },
    components: {
        LoopPdf
    }
}
</script>