<template>
    <section class="d-block">
        <h1 class="font-weight-bold color-principal mb-5">Lineas de atencion al ciudadano</h1>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Linea</th>
                    <th scope="col">Entidad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>#117</td>
                    <td>Llamada de emergencia a policia</td>
                </tr>
            </tbody>
        </table>
    </section>
</template>
<script>
export default {
    name: 'LineaAtencion',
    data() {
        return {

        }
    }
}
</script>
<style lang="css" scoped>
</style>