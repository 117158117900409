<template>
    <div class="d-block">
        <h5 class="color-principal text-uppercase mb-3">Documentos</h5>
        <div class="d-grid-pdf">
            <a class="columna item-pdf bg-white" target="_black" :href="item.url" v-for="(item, index) of loop" v-bind:key="index">
                <div class="d-flex mb-1" v-if="file(item.url) === 'pdf'">
                    <i class="icon-file-pdf h3 mr-3"></i>
                    <span>PDF</span>
                </div>
                <div class="d-flex align-items-center mb-1" v-else-if="file(item.url) === 'xls'">
                    <i class="icon-file-excel h3 mr-3"></i>
                    <span>EXCEL</span>
                </div>
                <div class="d-flex align-items-center mb-1" v-else-if="file(item.url) === 'lsx'">
                    <i class="icon-file-excel h3 mr-3"></i>
                    <span>EXCEL</span>
                </div>
                <div class="d-flex align-items-center mb-1" v-else-if="file(item.url) === 'ocx'">
                    <i class="icon-file-word h3 mr-3"></i>
                    <span>DOC</span>
                </div>
                <h6 class="m-0 text-uppercase">{{ item.titulo }}</h6>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LoopPdf',
    props: ['loop'],
    methods: {
        file(str){
            let text = str.slice(-3);
            return text;
        }
    }
}
</script>