<template>
    <div class="accordion mt-5" id="manualanexo">
        <div class="card" v-for="item of items" :key="item.id">
            <div class="card-header" id="uno">
                <h2 class="mb-0">
                    <button class="btn btn-link tema-principal btn-block text-left" type="button" data-toggle="collapse" :data-target="'#'+item.id_tab" aria-expanded="true" :aria-controls="item.id_tab">
                        {{ item.title }}
                    </button>
                </h2>
            </div>
            <div :id="item.id_tab" class="collapse" :aria-labelledby="item.id_tab" data-parent="#manualanexo">
                <div class="card-body">
                    {{ item.contexto }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Accordion',
    props: ['items']
}
</script>
<style lang="css" scoped>
.accordion button {
    text-decoration: none;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 0;
    font-weight: bold;
    text-transform: uppercase;
}
.accordion .card-header {
    text-decoration: none;
    padding: 0;
}
.accordion .card {
    border-color: #ff6d00;
}
.accordion .card + .card {
    border-top: 2px solid #fff;
}
</style>