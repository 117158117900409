<template>
    <TabsSideContainer :tabs="tabs">
        <template v-slot:body>
            <div class="tab-pane fade show active" id="tab-uno" role="tabpanel">
                <h3 class="color-principal">INFORME ASESOR JURIDICO</h3>
                <hr>
                <p>
                    A raíz del Covid-19, algunos arrendatarios de los diferentes locales solicitaron un acercamiento
                    económico respecto de los meses del aislamiento y el no funcionamiento de esta terminal los cuales
                    se realizaron bajo sus directrices, otros nunca aparecieron, por tanto, no hubo modificación en esas
                    carteras.
                </p>
                <p class="mb-5">
                    Por otra parte, se le hicieron seguimiento para el cumplimiento de todas las disposiciones legales del
                    orden nacional, departamental y distrital en razón de la pandemia; así mismo, se le presto apoyo al
                    doctor Jenner Hernández en todas las actuaciones y decisiones a tomar dentro del entorno de sus
                    labores en esta empresa, como también a la doctora Ana Beatriz Vives quien en la actualidad realiza
                    trabajaos desde su casa.
                </p>
                <LoopPdf :loop="pdfJuridicos"></LoopPdf>
            </div>
            <div class="tab-pane fade" id="tab-dos" role="tabpanel">
                <h3 class="mb-4">En cuanto a los procesos:</h3>
                <hr class="mb-4">
                <ListArchivos :lista="planAccion"></ListArchivos>
            </div>
            <div class="tab-pane fade" id="tab-tres" role="tabpanel">
                <h3 class="mb-5 color-principal">PLAN DE RESPUESTA DE EMERGENCIA Y CONTINGENCIA</h3>
                <div class="d-block mb-5">
                    <h5 class="color-principal">INTRODUCCIÓN</h5>
                    <hr class="decorativo borde-color-principal">
                    <p>El Plan de Emergencia de La Terminal Central de Transporte de Santa Marta, es el documento
                        que recopila los procedimientos de Atención de Emergencias el cual se ha elaborado bajo
                        criterios de funcionalidad y operación en el momento de su aplicación, teniendo como prioridad
                        principal, proteger la vida humana, las instalaciones, el medio ambiente y alrededores.</p>
                </div>
                <div class="d-block mb-5">
                    <h5 class="color-principal">JUSTIFICACIÓN</h5>
                    <hr class="decorativo borde-color-principal">
                    <p>La Terminal Central de Transporte de Santa Marta, preocupada por el bienestar y seguridad
                        de sus trabajadores, usuarios del transporte terrestre y de la comunidad en general así como el
                        tipo y calidad de información que maneja</p>
                </div>
                <div class="d-block mb-5">
                    <h5 class="color-principal">OBJETIVO GENERAL</h5>
                    <hr class="decorativo borde-color-principal">
                    <p>Diseñar, ejecutar y evaluar un Plan de Emergencia y Contingencia, para que sirva de soporte al
                        momento de presentarse una emergencia tanto en el área administrativa como operativa y se
                        pueda responder de una manera adecuada, oportuna y eficaz, dándole esta manera seguridad a
                        la integridad de las personas</p>
                </div>
                <ListArchivos :lista="objetivo"></ListArchivos>
            </div>
            <div class="tab-pane fade" id="tab-cuatro" role="tabpanel">
                <h3 class="color-principal">PLAN ESTRATÉGICO</h3>
                <p class="mb-4">Proceso que tiene por objetivo fijar el curso de las acciones que se deben seguir en el mediano y largo plazo, para garantizar la gestión y el cumplimiento de sus metas y proyectos.</p>
                <LoopPdf :loop="estrategico" class="mb-5"></LoopPdf>
                <h4 class="color-principal">INTRODUCCION</h4>
                <p class="mb-5">La Planificación Estratégica de talento humano, es un proceso mediante el cual la
                    organización realiza el estudio de sus necesidades cuantitativas y cualitativas de
                    recursos humanos a corto, medio y largo plazo, contrasta las necesidades
                    detectadas con sus capacidades internas, e identifica las acciones que deben
                    emprenderse para cubrir las diferencias, tiene por objetivo fijar el curso concreto
                    de acción que ha de seguirse, en el desarrollo de los planes, programas y proyectos
                    de la empresa Central de transporte de Santa Marta.</p>
                <ListArchivos :lista="estrategia"></ListArchivos>
            </div>
        </template>
    </TabsSideContainer>
</template>
<script>
import TabsSideContainer from '@/components/templates/tabs-side-container.vue';
import Title from '@/components/global/Title.vue';
import PortadaHeader from '@/components/global/PortadaHeader.vue'
import LoopPdf from '@/components/global/LoopPdf.vue'
import ListArchivos from '@/components/global/ListArchivos.vue'
export default {
    name: 'PlaneacionGestion',
    data() {
        return {
            tabs: [{
                    id: 'uno',
                    name: 'INFORME ASESOR JURIDICO'
                },
                {
                    id: 'dos',
                    name: 'Plan de acción'
                },
                {
                    id: 'tres',
                    name: 'Plan de Contingencia'
                },
                {
                    id: 'cuatro',
                    name: 'Planes Estrategico'
                },
            ],
            pdfJuridicos: [{
                url: 'http://terminaldesantamarta.com/wp-content/uploads/2020/11/INFORME_ASESOR_JURIDICO.pdf',
                titulo: 'INFORME ASESOR JURIDICO'
            }, ],
            planAccion: [{
                    archivo: 'e',
                    enlace: 'http://terminaldesantamarta.com/wp-content/uploads/2018/12/Plan-de-Acci%C3%B3n-Terminal15-03.xlsx',
                    titulo: 'Plan de Acción Terminal Santa Marta',
                    desc: 'Actividades ha ser desarrolladas dentro del periodo comprendido'
                },
                {
                    archivo: 'p',
                    enlace: 'http://terminaldesantamarta.com/wp-content/uploads/2019/03/PLANDEACCION2019.pdf',
                    titulo: 'PLAN DE ACCIÓN 2019',
                    desc: 'Actividades ha ser desarrolladas dentro del periodo comprendido de enero a diciembre de 2019'
                },
                {
                    archivo: 'p',
                    enlace: 'http://terminaldesantamarta.com/wp-content/uploads/2020/10/Plan-de-Acci%C3%B3n-Terminal-2020.pdf',
                    titulo: 'Plan de Acción Terminal 2020',
                    desc: 'Actividades ha ser desarrolladas dentro del periodo comprendido de enero a diciembre de 2020'
                },
                {
                    archivo: 'p',
                    enlace: 'http://terminaldesantamarta.com/wp-content/uploads/2021/03/Plan-de-Acci%C3%B3n-Terminal-2021.pdf',
                    titulo: 'Plan de Acción Terminal 2021',
                    desc: 'Actividades ha ser desarrolladas dentro del periodo comprendido'
                },
                {
                    archivo: 'e',
                    enlace: 'https://terminaldesantamarta.com/documentos/Plan-de-Accion-Terminal-2022.xlsx',
                    titulo: 'Plan de Acción Terminal 2022',
                    desc: 'Actividades ha ser desarrolladas dentro del periodo comprendido'
                },
                {
                    archivo: 'e',
                    enlace: 'https://terminaldesantamarta.com/documentos/2023/Plan-de-Accion-Terminal-2023.xlsx',
                    titulo: 'Plan de Accion Terminal 2023',
                    desc: '2023'
                },
            ],
            objetivo: [{
                archivo: 'p',
                enlace: 'https://terminaldesantamarta.com/documentos/Plan_Emergencia_CTSM.pdf',
                titulo: 'Plan de Emergencia',
                desc: 'PLAN DE RESPUESTA DE EMERGENCIA Y CONTINGENCIA'
            }, ],
            estrategico: [{
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/04/PLAN-ESTRATEGICO-2016-2019.pdf',
                    titulo: 'PLAN ESTRATEGICO 2016 – 2019'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/PLAN-ESTRATEGICO-2020-2023-V4-2022.pdf',
                    titulo: 'PLAN ESTRATÉGICO 2020 – 2023'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/PLAN-ESTRATEGICO-DE-TALENTO-HUMANO-CENTRAL-DE-TRANSPORTE-DE-SANTA-MARTA-2022.pdf',
                    titulo: 'PLAN ESTRATEGICO DE TALENTO HUMANO CENTRAL DE TRANSPORTE DE SANTA MARTA 2022'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/CRONOGRAMA-PLAN-DE-BIENESTAR-SOCIAL.xlsx',
                    titulo: 'CRONOGRAMA PLAN DE BIENESTAR SOCIAL'
                },
                {
                    url: 'https://terminaldesantamarta.com/documentos/2023/CRONOGRAMA-PLAN-DE-BIENESTAR-SOCIAL-2023.xlsx',
                    titulo: 'CRONOGRAMA PLAN DE BIENESTAR SOCIAL 2023'
                }
            ],
            estrategia: [{
                archivo: 'p',
                enlace: 'https://terminaldesantamarta.com/documentos/2023/PLAN-ESTRATEGICO-DE-TALENTO-HUMANO-2023.docx',
                titulo: 'Plan Estratégico Talento Humano 2023',
                desc: '2023'
            },{
                archivo: 'p',
                enlace: 'http://terminaldesantamarta.com/wp-content/uploads/2021/02/Plan-Estrategico-Talento-Humano-2021.pdf',
                titulo: 'Plan Estratégico Talento Humano 2021',
                desc: '2021'
            }, ]
        }
    },
    components: {
        PortadaHeader,
        TabsSideContainer,
        ListArchivos,
        Title,
        LoopPdf
    }
}
</script>
<style lang="css">
hr.decorativo {
    border-width: 2px;
    width: 5rem;
    margin-right: auto;
    margin-left: 0;
}
</style>