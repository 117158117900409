<template>
    <div class="container-fluid">
        <PortadaHeader :msj="msj"></PortadaHeader>
        <div class="container container-seccion-entidad">
            <div :class="['row seccion-entidad align-items-start', { 'enpujar' : activo } ]">
                <div class="col-lg-4 col-6">
                    <div class="list-group position-sticky" id="list-tab" role="tablist" style="top: 5rem;">
                        <a
                            @click="verSeleccionado"
                            class="list-group-item list-group-item-action active"
                            id="list-home-list"
                            data-toggle="list"
                            href="#list-home"
                            role="tab"
                            aria-controls="home">Llenar</a>
                    </div>
                </div>
                <div class="col-lg-8 col-6 lista-texto">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="d-block mb-3 boton-volver">
                            <button class="btn tema-principal" @click="verSeleccionado">
                                Volver
                            </button>
                        </div>
                        <!-- secciones tabs -->
                        <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                            Vacio
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PortadaHeader from '@/components/global/PortadaHeader.vue'
export default {
    name: 'Contrataciones',
    data() {
        return {
            activo: false,
            msj: {
                titulo: 'Contrataciones',
                desc: 'Contrato de servicios',
                img: 'foto-2.jpg'
            }
        }
    },
    components: {
        PortadaHeader,
    },
    methods: {
        verSeleccionado(){
            if (screen.width <= 768) {
                this.activo = !this.activo;
            }
        }
    }
}
</script>
<style>

</style>