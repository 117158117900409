<template>
    <section class="d-block">
        <h1 class="font-weight-bold color-principal mb-5">Informe de Gestion</h1>
        <article class="d-block mb-4">
            <h5 class="text-uppercase color-principal mb-3">Informe de Gestión Año 2018 </h5>
            <p>La Gerencia de la Central de Transporte de Santa Marta, sigue trabajando para cumplir con las
                leyes, decretos, normas, resoluciones, y planes de acción, que le imponen entidades como la
                Superintendencia de Puertos y Transportes, Ministerio de Transporte, Contaduría General de la
                Nación, <span class="font-weight-bold color-principal">DIAN</span>, <span class="font-weight-bold color-principal">DADSA</span>, Secretaria de Salud entre otros, en razón a su actividad económica y
                naturaleza jurídica; siendo que está clasificada como una Empresa Industrial y Comercial del
                Estado. Y con esto, tratamos de suplir las deficiencias, corregir errores, y adaptar la empresa a
                los nuevos retos que establece su actividad en el ámbito local, nacional e internacional</p>
        </article>
        <article class="d-block mb-4">
            <h5 class="text-uppercase color-principal mb-3">Informe Talento Humano </h5>
            <p>Uno de los objetivos propuesto hace algunos años, fue el de alcanzar la Certificación ISO 9001
                2015 por parte de Icontec para nuestra Empresa. Este objetivo tuvo sus inicios en el año de 2016,
                con la implementación de la NTC GP 1000, que es la misma gestión de Calidad, para las
                Empresas Estatales. Esta norma fue derogada, por lo que continuamos nuestro proceso de
                certificación con la ISO 9001 2015; motivo por el cual todo nuestro trabajo se vio encaminado a
                conseguir dicho propósito.</p>
        </article>
        <article class="d-block mb-4">
            <h5 class="text-uppercase color-principal mb-3">Sistemas y TIC</h5>
            <p>La evolución alígera de las tecnologías de la información y las comunicaciones se ha convertido
                en una variable, para tener en cuenta, en el desarrollo de los procesos de las empresas. Más que
                identificarse como una ayuda, es una herramienta de apoyo fundamental para alcanzar la
                eficiencia y posterior eficacia dentro de la organización. </p>
        </article>
        <LoopPdf :loop="pdf"></LoopPdf>
    </section>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue'
export default {
    name: 'InformeGestion',
    data() {
        return {
            pdf: [
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/03/InformeGesti%C3%B3n.pdf',
                    titulo: 'Informe de Gestión 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2020/01/INFORME-DE-GESTION-A%C3%91O-2019.pdf',
                    titulo: 'Informe de Gestión 2019'
                },
            ]
        }
    },
    components: {
        LoopPdf
    }
}
</script>
<style lang="css" scoped>
</style>