<template>
    <section class="d-block">
        <h1 class="font-weight-bold color-principal mb-5">Contratos</h1>
        <LoopPdf :loop="pdf"></LoopPdf>
    </section>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue'
export default {
    name: 'Contratos',
    data() {
        return {
            pdf: [
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2020/11/Contratos.pdf',
                    titulo: 'Contratos 2020'
                },
            ]
        }
    },
    components: {
        LoopPdf
    }
}
</script>
<style lang="css" scoped>
</style>