export const preguntas = [
    {
        title: '1. Que rutas tiene habilitadas la Terminal de Transporte de Santa Marta hacia las diferentes ciudades de Colombia',
        respuesta: 'Para consultar nuestros destinos de viaje consulta el siguiente enlace:'
    },
    {
        title: '2. A que municipios del Departamento del Magdalena puedo via jar desde la terminal de Santa Marta?',
        respuesta: 'Desde nuestra Terminal puedes viajar hacia los siguientes municipios : Banco, Fundación, Zona Bananera, Salamina, Ciénaga y Santa Ana.'
    },
    {
        title: '3. Donde puedo registrar una PQRSD',
        respuesta: 'Lo puede hacer a tra vés de nuestra página web en el siguiente enlace: A través de nuestro correo informacionpqr@terminaldesantam@arta.com De manera presencial en nuestras oficinas de Gerencia o en la oficina de información y PQRSD.'
    },
    {
        title: '7. Se puede desistir de la compra de los pasajes?',
        respuesta: 'Los usuarios podrán desistir del transporte contratado con derecho a la devolución total o parcial del dinero pagado, dando previo aviso al transportador, conforme se establezca en el contrato o la costumbre mercantil.'
    },
    {
        title: '8 . Hasta que horas está abierta la Terminal de Transporte de Santa Marta',
        respuesta: 'Opera las 24 horas del día, de domingo a domingo, en horario continuo, para la prestación del servicio a los usuarios.'
    },
    {
        title: '9. Q ue otros servicios podemos encontrar en la Central de Transporte de Santa Marta',
        respuesta: `Además de los servicios de Transporte de pasajeros interdepartamental
y municipal, los usuarios pueden acceder a los siguientes servicios:
control Op erativo de Vehículos, estación de Taxis, cajeros automáticos,
locales comerciales, restaurantes, servicio de baños públicos, servicios
de inf ormación y pqrsd , servicio de parqueadero privado, servicio de
parqueadero operativo, servicio de policía nacional, servicio de giros y
encomiendas, sala VIP, servicio de guarda equipajes, estación de
servicios`
    },
    {
        title: `10. ¿ Debo usar mascarilla o tapabocas dentro de las instalaciones
de la Terminal o dentro del bus en el que viajo, así este vacunado
contra el Covid 19?`,
        respuesta: `Si debe usarse, de acuerdo con la Resolución 1238 de Julio 21 de
2022 : ARTÍCULO 7. Uso de tapabocas. Es obligatorio el uso de
tapabocas en personas mayores de 2 años en las instituciones de
salud, hogares geriátricos, en el transporte público, estaciones de
transporte masivo, los terminales de transporte aéreo, terrestre ,
marítimo y fluvial, sin importar el avance de la vacunación`
    },
    {
        title: '4. Puede viajar mi mascota en el autobús?',
        respuesta: `Los usuarios del servicio de transporte público en el modo aéreo, terrestre y acuático podrán transportar sus mascotas y animales de asistencia, siempre y cuando sean tenidos y transportados en condiciones de salubridad, seguridad, comodidad y tranquilidad.
Para más información puede acceder al siguiente enlace:`
    },
    {
        title: `5. Cuáles son los puntos habilitados para el despacho de las
Empresas Transportadoras adscritas en la Terminal de
Transporte de Santa Marta?`,
        respuesta: `Desde la sede ubicada en la carretera Troncal del Caribe
Variante Gaira - Mamatoco, calle 41 No 31-17`
    },
    {
        title: `6. Es obligación de las empresas de transporte entregar el tiquete a los usuarios?`,
        respuesta: `Es obligatoria la entrega de tiquetes a los usuarios puesto que es el contrato entre el cliente y la empresa de transporte.`
    }
]