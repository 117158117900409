<template>
    <div class="container list-documento">
        <div class="row mb-4" v-for="item of lista" :key="item">
            <div class="documento bg-blanco">
                <i :class="['color-principal', icono(item.archivo)]"></i>
            </div>
            <div class="col">
                <p class="mb-1 h6 text-uppercase">{{ item.titulo }}</p>
                <p class="mb-1 desc">{{ item.desc }}</p>
                <a target="_black" :href="item.enlace">Ver archivo</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ListArchivos',
    props: ['lista'],
    methods: {
        icono(i) {
            let icon;
            switch (i) {
                case 'e':
                    icon = 'icon-file-excel'
                    break;
                case 'w':
                    icon = 'icon-file-word'
                    break;
                case 'p':
                    icon = 'icon-file-pdf'
                    break;
                default:
                    icon = 'icon-libreoffice'
                    break;
            }
            return icon;
        }
    }
}
</script>
<style lang="css">
.list-documento .documento {
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    font-size: 2rem;
}

.list-documento .desc {
    color: #989898;
}
</style>
<!--
import ListArchivos from '@/components/global/ListArchivos.vue'

<ListArchivos :lista="lista"></ListArchivos>

e = excel
w = word
p = pdf
datos item
lista [
    {
        archivo: 'e',
        enlace: 'url',
        titulo: 'titulo',
        desc: 'descripcion'
    }
]
-->