<template>
    <div>
        <h1 class="color-principal">Control Interno</h1>
        <p>Proceso encargado de evaluar, controlar y verificar las distintas actividad desarrolladas dentro de la organización.</p>
        <article class="d-block my-5">
            <h3 class="color-principal">CERTIFICADO DE RECEPCIÓN DE INFORMACIÓN</h3>
            <p>La Dirección de Gestión y Desempeño Institucional del Departamento
                Administrativo de la Función Pública, certifica que la institución
                CENTRAL DE TRANSPORTES DE SANTA MARTA LTDA., a través del
                usuario furag1414JCIT, diligenció el Formulario de Reporte de Avances de
                la Gestión - FURAG, correspondiente a la vigencia 2018 en el mes durante
                el mes de febrero y marzo de 2019.</p>
            <p class="mt-3">Para consultar la información detallada diríjase a Datos Abiertos</p>
        </article>
        <article class="d-block my-5 ml-5">
            <h3 class="color-principal">Objetivos</h3>
            <p>Rendir informe trimestral de austeridad en el gasto público, respecto de los gastos
                generados por la Entidad, durante el tercer trimestre de la vigencia fiscal 2020. - Verificar
                que el gasto se oriente a la racionalidad y uso adecuado de los recursos en la Entidad. -
                Verificar el cumplimiento de las normas vigentes de Austeridad y Eficiencia en el Gasto
                Público.</p>
        </article>
        <article class="d-block my-5 ml-5">
            <h3 class="color-principal">Principios</h3>
            <p>Todas las actividades y recursos de la entidad deben estar dirigidos al logro de sus
                objetivos y metas, exigiendo la elaboración oportuna y ejecución de los planes y
                programas, así como la verificación de la idoneidad y debido cumplimiento e identificando
                los ajustes necesarios.</p>
        </article>
        <LoopPdf :loop="pdf"></LoopPdf>
    </div>
</template>
<script>
import LoopPdf from '@/components/global/LoopPdf.vue'
export default {
    name: 'ControlInterno',
    data: function() {
        return {
            pdf: [{
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/03/Informe-de-Evaluaci%C3%B3n-de-Control-Interno-2018.pdf',
                    titulo: 'Informe de Evaluación de Control Interno 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/08/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-PRIMER-TRIMESTRE-2018.pdf',
                    titulo: 'INFORME DE AUSTERIDAD EN EL GASTO PUBLICO PRIMER TRIMESTRE 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/08/INFORME-DE-AUSTERIDAD-EN-EL-GASTO-PUBLICO-TERCER-TRIMESTRE-2018.pdf',
                    titulo: 'INFORME DE AUSTERIDAD EN EL GASTO PUBLICO TERCER TRIMESTRE 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/08/Infor-de-Seguimiento-Avances-Plan-de-mejoramiento-a-NOVIEMBRE-2018.pdf',
                    titulo: 'Infor de Seguimiento Avances Plan de mejoramiento a NOVIEMBRE 2018'
                },
                {
                    url: 'http://terminaldesantamarta.com/wp-content/uploads/2019/08/Certificado-de-Cumplimiento-FURAG-II-4-1.pdf',
                    titulo: 'CERTIFICADO FURAG'
                },
            ]
        }
    },
    components: {
        LoopPdf
    }
}
</script>
<style lang="css" scoped>
</style>