<template src="@/components/entidad/peticionesQuejas/pqr/pqr-template.vue"></template>
<style src="@/components/entidad/peticionesQuejas/pqr/pqr.css"></style>

<script>
import axios from 'axios';
export default {
    name: 'pqr',
    data: function() {
        return {
            domfData: {
                name: '',
                email: '',
                document: '',
                title: '',
                body: '',
                status: 1,
                city: '',
                neighborhood: '',
                phone: ''
            },
            etiqueta: null,
            etiquetasSelect: [],
            etiquetas: [{
                    id: 0,
                    title: 'Fuerza'
                },
                {
                    id: 1,
                    title: 'Nuevo'
                },
                {
                    id: 2,
                    title: 'Buena Atencion'
                },
                {
                    id: 3,
                    title: 'Gratitud'
                },
                {
                    id: 4,
                    title: 'Enojo'
                }
            ]
        }
    },
    computed: {
        addEtiqueta() {
            if (this.etiqueta != null) {
                let item = this.etiquetasSelect.find(elem => elem.id == this.etiqueta);
                if (!item) {
                    this.etiquetasSelect.push(this.etiquetas[this.etiqueta]);
                    this.etiqueta = null;
                }
            }
        }
    },
    methods: {
        eleiminarEtiqueta(item) {
            this.etiquetasSelect.splice(parseInt(item), 1);
        },
        sendPqr(){
            let tags = '';
            let tag = this.etiquetasSelect.map(item => {
                return item.title;
            })
            tag.forEach(item => {
                tags += item + ',';
            })
            let itemTags = tags.substring(0, tags.length - 1);
            console.log();
            let bodyFormData = new FormData();
            bodyFormData.append('requester[name]', this.domfData.name);
            bodyFormData.append('requester[email]', this.domfData.email);
            bodyFormData.append('requester[document]', this.domfData.document);
            bodyFormData.append('title', this.domfData.title);
            bodyFormData.append('tags', itemTags);
            bodyFormData.append('body', this.domfData.body);
            bodyFormData.append('status', this.domfData.status);
            bodyFormData.append('requester[city]', this.domfData.city);
            bodyFormData.append('requester[city]', this.domfData.neighborhood);
            bodyFormData.append('requester[phone]', this.domfData.phone);
            axios({
                method: "POST",
                url: "http://159.223.143.15/api/pqrsd-externo",
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*"
                },
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>